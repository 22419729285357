
import http from "@/common/http";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import Edit from "./Edit.vue";
import { message } from "ant-design-vue";
import { showConfirm } from "@/common/utils";

const columns = [
  // {
  //   title: "id",
  //   width: 100,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  // {
  //   title: "userId",
  //   width: 100,
  //   dataIndex: "userId",
  //   ellipsis: true,
  // },

  {
    title: "昵称",
    width: 180,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "可创建俱乐部的数量",
    width: 150,
    dataIndex: "clubNum",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
    ellipsis: true,
  },

  {
    title: "",
    align: "center",
    dataIndex: "action",
    fixed: "right",
    width: 140,
  },
];

export default defineComponent({
  components: {
    Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_white_list", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击编辑按钮
    const showEdit = (row: any) => {
      editId.value = row.id;
      visible.value = true;
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "删除确认",
        content: "确定删除该条记录吗?",
        onOk: async () => {
          await http.delete(`us_club_white_list/${row.id}`);
          getList();
          message.success("删除成功");
        },
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      showEdit,
      editId,
      del,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
    };
  },
});
