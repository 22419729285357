<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="search.data.maskId" placeholder="请输入游戏ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <!-- <a-col v-bind="searchCol">
            <a-form-item label="userId" name="userId">
              <a-input v-model:value.number="search.data.userId" placeholder="请输入userId" autocomplete="off" allowClear />
            </a-form-item>
          </a-col> -->
        </l-search-container>
      </a-form>
    </template>

    <div style="margin-bottom: 10px; text-align: right">
      <l-auth-button auth="us_club_white_list_create_btn" type="primary" @click="visible = true">
        <PlusOutlined />
        开通会长
      </l-auth-button>
    </div>
    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="item.maskId + ` - ${item.nickName}`" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="游戏ID">{{ item.maskId }}</a-descriptions-item>
                <!-- <a-descriptions-item label="userId">{{ item.userId }}</a-descriptions-item> -->
                <a-descriptions-item label="昵称">{{ item.nickName }}</a-descriptions-item>
                <a-descriptions-item label="可创建俱乐部数量">{{ item.clubNum }}</a-descriptions-item>
                <a-descriptions-item label="创建时间">{{ item.createTime }}</a-descriptions-item>
              </a-descriptions>

              <template #actions>
                <l-auth-text-button auth="us_club_white_list_update_btn" type="primary" primary @click="showEdit(item)">
                  <EditFilled />
                  编辑
                </l-auth-text-button>
                <l-auth-text-button type="danger" auth="us_club_white_list_del_btn" primary @click="del(item)">
                  <DeleteFilled />
                  删除
                </l-auth-text-button>
              </template>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="id" :loading="loading">
      <template v-slot:toolbar-action>
        <l-auth-button auth="us_club_white_list_create_btn" type="primary" @click="visible = true">
          <PlusOutlined />
          开通会长
        </l-auth-button>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'avatar'">
          <a-avatar :size="50" :src="record.url"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'state'">
          <a-tag :color="record.state == 1 ? 'success' : 'error'">{{ record.state == 1 ? "启用" : "禁用" }}</a-tag>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button auth="us_club_white_list_update_btn" type="primary" primary @click="showEdit(record)">
            <EditFilled />
            编辑
          </l-auth-text-button>
          <l-auth-text-button type="danger" auth="us_club_white_list_del_btn" primary @click="del(record)">
            <DeleteFilled />
            删除
          </l-auth-text-button>
        </template>
      </template>
    </l-table> -->

    <a-modal v-model:visible="visible" :title="editId == 0 ? '新增' : '编辑'" @cancel="visible = false" destroyOnClose>
      <Edit ref="edit_ref" @close="visible = false" @success="onEditSuccess" v-model:id="editId" />
    </a-modal>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import Edit from "./Edit.vue";
import { message } from "ant-design-vue";
import { showConfirm } from "@/common/utils";

const columns = [
  // {
  //   title: "id",
  //   width: 100,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  // {
  //   title: "userId",
  //   width: 100,
  //   dataIndex: "userId",
  //   ellipsis: true,
  // },

  {
    title: "昵称",
    width: 180,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "可创建俱乐部的数量",
    width: 150,
    dataIndex: "clubNum",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
    ellipsis: true,
  },

  {
    title: "",
    align: "center",
    dataIndex: "action",
    fixed: "right",
    width: 140,
  },
];

export default defineComponent({
  components: {
    Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_white_list", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击编辑按钮
    const showEdit = (row: any) => {
      editId.value = row.id;
      visible.value = true;
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "删除确认",
        content: "确定删除该条记录吗?",
        onOk: async () => {
          await http.delete(`us_club_white_list/${row.id}`);
          getList();
          message.success("删除成功");
        },
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      showEdit,
      editId,
      del,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
    };
  },
});
</script>
