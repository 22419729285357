<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="formState.maskId" placeholder="请输入游戏ID" autocomplete="off" :disabled="id != 0" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="可创建的俱乐部数量" name="clubNum">
              <a-input-number v-model:value.number="formState.clubNum" placeholder="请输入可创建的俱乐部数量" autocomplete="off" style="width: 100%" :min="0" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import { paltformTypes } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const fileList = ref<any[]>([]);
    const formState = ref({
      maskId: "",
      clubNum: 5,
    });

    //验证规则
    const rules = {
      maskId: [{ required: true, message: "请输入游戏ID", trigger: "change", type: "number" }],
      clubNum: [{ required: true, message: "请输入俱乐部数量", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    const getData = async () => {
      loading.value = true;
      if (props.id) {
        //获取编辑数据
        const { data } = await http.get(`us_club_white_list/${props.id}`);
        formState.value = data.data;
      }

      loading.value = false;
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          if (!props.id) {
            //新增
            await http.post("us_club_white_list", formState.value);
            message.success("新增成功");
          } else {
            //修改
            await http.put(`us_club_white_list/${props.id}`, formState.value);
            message.success("修改成功");
          }
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    onMounted(() => {
      getData();
    });

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      fileList,
      onClose,
      handleSubmit,
    };
  },
});
</script>
